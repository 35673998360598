import { AccountCircleRounded, ContactMail, Diamond, DiamondOutlined, Edit } from "@mui/icons-material";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled, useTheme, useMediaQuery, IconButton, Snackbar, CircularProgress, Typography } from "@mui/material";
import { padding } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import LinkedIn from "./../../../images/linkedin.png";
import Facebook from "./../../../images/facebook.png";
import Instagram from "./../../../images/instagram.png";
import Twitter from "./../../../images/twitter.png";
import Wechat from "./../../../images/wechat.png";
import Tiktok from "./../../../images/tiktok.png";
import Icon from "./../../../images/FW_ Final Hour improvement/assets-2.png"
import Assets1 from "./../../../images/FW_ Final Hour improvement/Assets-1.png"
import MuiAlert from "@mui/material/Alert";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import html2canvas from "html2canvas";
import { Helmet } from "react-helmet";
import HelmetMetaData from "../../HelmetMetaData";
import NoImage from "./../../../images/no-pictures.png";
import MetaIcon from './../../../images/metaicon.png'
import Xicon from './../../../images/Xicon.png'
import LinkedinLogo from './../../../images/linkedinIcon.png'
import InviteDialog from "../InviteDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileSection = styled("div")(({ theme }) => ({
  padding: "40px 0",
  background: theme.palette.primary.lightest,
  "& p1": {
    margin: "30px 50px ",
    fontSize: "13px",
    fontWeight: 600,
    color: "#9a6d97"
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
    "& p1": {
      marginLeft: "30%",
    },
  },
}));

const ImageSection = styled("div")(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  "& img": {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "50%",
    marginBottom: "20px",
  },

}));
const SocialDiv = styled("div")(({ theme }) => ({
  "& img": {
    width: "25px",
    height: "25px",
  },
  "& a": {
    margin: "0 3px",
  },
}));

const EditDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  position: "absolute",
  right: "35px",
  bottom: "60px",
}));
const GemDiv = styled("div")(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "50%"
}));

const ProfileGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 0.75fr",
  gridGap: "10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const ProfileGridGrey = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "0.5fr 3fr 1fr",
  gridGap: "10px",
  background: theme.palette.primary.grey,
  margin: "20px 0",
  borderRadius: "10px",
  padding: "0px 0px 0px 10px",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "0.95fr",
  },
}));

const InfoBlock = styled("div")(({ theme }) => ({
  "& strong": {
    fontSize: "20px",
  },
  "& span": {
    fontSize: "20px",
  },
  "& span1": {
    color: theme.palette.primary.greyText,
  },
}));

const RedBlock = styled("div")(({ theme }) => ({
  //padding: "10px 40px",
  padding: "10px 0px 0px 0px",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.primary.red,
  borderRadius: "100px",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0px 0px 30px !important",
    "& div": {
      fontSize: "30px !important",
      marginRight: "10px !important",
    },
    "& span": {
      fontWeight: "400 !important",
      lineHeight: "1.2 !important",
      fontSize: "14px !important",
    },
  },
  "& div": {
    fontSize: "55px",
    marginRight: "20px",
  },
  "& span": {
    fontWeight: "300",
    lineHeight: "1.4",
    fontSize: "16px",
  },


}));

const BlockWrapper = styled("div")(({ theme }) => ({
  marginTop: "5px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "start",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));


const Block = styled("div")(({ theme }) => ({
  padding: "5px 20px 0px 5px",
  color: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "5px",
  //background: theme.palette.primary.red,
  borderRadius: "100px",
  border: `1px solid #cdccc3`,
  maxWidth: "290px",
  [theme.breakpoints.down("md")]: {
    padding: "5px 5px 0px 5px !important",
    "& div": {
      fontSize: "18px !important",
      marginRight: "10px !important",
    },
    "& span": {
      fontWeight: "300 !important",
      //lineHeight: "1.2 !important",
      fontSize: "14px !important",
    },
  },
  "& icon": {
    fontSize: "18px",
    marginRight: "0px",
  },
  "& div": {
    fontSize: "18px",
    marginRight: "0px",
    color: theme.palette.primary.red
  },
  "& span": {
    fontWeight: "300",
    // lineHeight: "1.4",
    fontSize: "16px",
  },


}));

// const GemBlock = styled("div")(({ theme }) => ({
//   padding: "10px 30px",
//   color: "#fff",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   cursor: "pointer",
//   background: theme.palette.primary.red,
//   borderRadius: "100px",
//   [theme.breakpoints.down("md")]: {
//     padding: "10px",
//   },
//   "& div": {
//     fontSize: "55px",
//     marginRight: "20px",
//   },
//   "& span": {
//     fontWeight: "300",
//     lineHeight: "1.4",
//     fontSize: "16px",
//   },


// }));

const GemBlock = styled("div")(({ theme }) => ({
  padding: "10px 0px 0px 0px",
  color: "#fff",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  //background: "#D8BFD8",
  background: "#b991b3",
  border: "2px solid #800080",
  borderRadius: "100px",
  marginLeft: "15%",
  width: "150px",
  height: "40px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "10px 0px 0px 0px",
    marginLeft: "30%",
  },
  "& div": {


  },

  "& span": {
    fontWeight: "300",
    marginLeft: "20px",
    lineHeight: "1.4",
    fontSize: "16px",
  },

}));


const TextComponent = styled("div")(({ theme }) => ({
  fontWeight: "500",
  //lineHeight: "1.4",
  fontSize: "20px !important",
  color: "red",
  //marginLeft: "20px",

}));

const MemberFlex = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  "& h5": {

  },
  "& h2": {
    marginTop: "-15px",
    fontWeight: "300",
    color: "#ffffff"
  },
}));

const HoursDiv = styled("div")(({ theme }) => ({
  background: "#a3a095",
  textAlign: "center",
  marginLeft: "60px",
  borderRadius: "10px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0",
  },
  "& div": {
    marginTop: "-15px",
  }
  ,
  "& span": {
    fontSize: "32px",
    margin: "0",
    fontWeight: "300",
    color: "#ffffff"
  },
  "& span1": {
    fontSize: "18px",
    margin: "0",
    fontWeight: "300",
    color: "#ffffff"
  },
}));


const ItemsBox1 = styled(Box)(({ theme }) => ({
  minWidth: "700px",
  borderBottom: "1px solid #999",
  padding: "10px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",

}));

const ItemsBox = styled(Box)(({ theme }) => ({

  borderBottom: "1px solid #999",
  padding: "10px 0px",
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "left",

}));

const Name = styled(Box)(({ theme }) => ({
  width: "410px",
  marign: "0 10px",

}));

const Hours = styled(Box)(({ theme }) => ({
  width: "240px",

}));
const Token = styled(Box)(({ theme }) => ({
  width: "240px",

}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexContent1 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "280px",
  maxWidth: "320px",
  //fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginRight: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  "& input": {
    padding: "10px",
    width: "100px",
  },
  "& .MuiSelect-select": {
    padding: "10px",
  },
  "& button": {
    padding: "11px !important",
    minWidth: "40px",
  },
}));



const MainProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [totalHours, setTotalHours] = useState("");
  const [memberStatus, setMemberStatus] = useState({});
  const [dashboardImage, setDashboardImage] = useState("");
  const [gemOpen, setGemOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const user = localStorage.getItem("login");
  const userData = JSON.parse(user);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  //const [metaTags, setMetaTags] = useState({});

  // useEffect(() => {
  //   const fetchMetaTags = async () => {
  //     const response = await fetch(`https://stagingapi.finalhour.org/api/app/1.0/frontend/member/html/${191}`);
  //     const html = await response.text();
  //     const parser = new DOMParser();
  //     const doc = parser.parseFromString(html, 'text/html');

  //     setMetaTags({
  //       title: doc.querySelector('meta[property="og:title"]').getAttribute('content'),
  //       description: doc.querySelector('meta[property="og:description"]').getAttribute('content'),
  //       image: doc.querySelector('meta[property="og:image"]').getAttribute('content'),
  //     });
  //   };

  //   fetchMetaTags();
  // }, []);


  useEffect(() => {
    // getMemberStatus();
    getTotalHours();
  }, []);

  useEffect(() => {
    generateImageFromDiv();
  }, [userData])

  const generateImageFromDiv = async (divElement) => {
    var divElement = document.getElementById("dashboard_status");
    try {
      const canvas = await html2canvas(divElement);
      const canvasImage = canvas.toDataURL('image/png');
      setDashboardImage(canvasImage);


      // console.log('inside dashbard')

      // Helmet.rewind(); // Reset the Helmet data
      // Helmet.addElement("meta",{
      //   property: 'og:image',
      //   content: canvasImage,
      // });
      // Helmet.addElement("meta",{
      //   property: 'og:description',
      //   content: "Final Hour - It's about time. It doesn’t matter, who you are or what you do for a living, your time is valuable."+
      //   "Help make a difference in the world by donating an hour of it." + 
      //   "Give the final hour of your working day, week or month to your preferred causes.",
      // });
      // Helmet.addElement("meta",{
      //   property: 'og:site_name',
      //   content: "FinalHour",
      // });
      // Helmet.addElement("meta",{
      //   property: 'og:url',
      //   content: window.location.href,
      // });
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  // console.log('user data', userData);

  // const getMemberStatus = () => {
  //   httpClient.get('loyalty-rules').then(({data}) => {
  //     if(data.success){
  //       setMemberStatus(data.data)
  //     }
  //   })
  // }
  const handleInviteDialog = () => {
    setOpenInvite(true);
  }

  const callBack = (call, formData) => {
    if (call.open === false) {
      setOpenInvite(false)
    }
    if (call.success === true) {
      setLoadingInvite(true);
      httpClient.post(`member/invite`,
        formData
      ).then(({ data }) => {
        if (data.success) {
          setLoadingInvite(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

        } else {
          setLoadingInvite(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
    }
  }

  const getTotalHours = () => {
    httpClient.get("total-hours").then(({ data }) => {
      if (data.success) {
        setTotalHours(data.sumHours);
        setMemberStatus(data);
      }
    });
  };

  const handleProfile = () => {
    navigate("/dashboard/account_settings");
  };

  // console.log('user data', userData);
  // console.log('dashboard image', dashboardImage)
  // console.log("window", window.location);
  const gemList = [
    {
      id: 1,
      tokensAsTime: "The Final Hour",
      hours: "1",
      token: "60"
    },
    {
      id: 2,
      tokensAsTime: "Working Hours / Day",
      hours: "8",
      token: "480"
    },
    {
      id: 3,
      tokensAsTime: "Working Hours / Week",
      hours: "38",
      token: "2,280"
    },
    {
      id: 4,
      tokensAsTime: "Working Hours / Month",
      hours: "152",
      token: "9,120"
    },
    {
      id: 5,
      tokensAsTime: "Working Hours / 3 months",
      hours: "456",
      token: "27,360"
    },
    {
      id: 6,
      tokensAsTime: "Working Hours / 6 months",
      hours: "912",
      token: "54,720"
    },
    {
      id: 7,
      tokensAsTime: "Working Hours / Year",
      hours: "1,824",
      token: "109,440"
    },

  ];


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <ProfileSection id="dashboard_status">
      {/* <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Test description" />
          <meta property="url" content={`https://www.finalhour.retailcare.com.au/dashboard`} />
          <meta property="og:image" content={dashboardImage} />
          <meta property="og:type" content="website" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:site_name" content="FinalHour" />

        </Helmet> */}
      <HelmetMetaData
        title={"Final Hour " + userData.member.firstName + " " + userData.member.lastName}
        description={userData.member.firstName + " has contributed " + totalHours + "hours and supported many causes."}
        image={userData.member.image}
      />

      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item md={2} xs={12}>
            <ImageSection>
              <img
                src={userData.member.image ? userData.member.image : NoImage}
                alt={userData.member.firstName + " " + userData.member.lastName}
              />
              <EditDiv>
                <Edit fontSize="small" onClick={handleProfile} />
              </EditDiv>
              <SocialDiv>
                {userData.member.linkedInURL && (
                  <a href={`https://${userData.member.linkedInURL}`} target="_blank">
                    <img src={LinkedIn} alt="linkedin" />
                  </a>
                )}
                {userData.member.twitterURL && (
                  <a href={userData.member.twitterURL} target="_blank">
                    <img src={Twitter} alt="twitter" />
                  </a>
                )}
                {userData.member.weChatURL && (
                  <a href={`https://${userData.member.weChatURL}`} target="_blank">
                    <img src={Wechat} alt="wecaht" />
                  </a>
                )}
                {userData.member.facebookURL && (
                  <a href={`https://${userData.member.facebookURL}`} target="_blank">
                    <img src={Facebook} alt="facebook" />
                  </a>
                )}
                {userData.member.instagramURL && (
                  <a href={`https://${userData.member.instagramURL}`} target="_blank">
                    <img src={Instagram} alt="insta" />
                  </a>
                )}
                {userData.member.tiktokURL && (
                  <a href={userData.member.tiktokURL} target="_blank">
                    <img src={Tiktok} alt="tiktok" />
                  </a>
                )}
              </SocialDiv>


            </ImageSection>
            <GemBlock onClick={() => handleInviteDialog()}>
              <div>
                <AccountCircleRounded sx={{
                  marginLeft: "-5px",
                  color: "#a483a1",
                  fontSize: "60px"
                }} />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-30%",
                    left: "20%",
                    color: "red",
                    fontSize: "38px",
                    fontWeight: "bold"
                  }}
                >
                  +
                </Typography>{/* Example icon, you can replace this with an actual icon component */}
              </div>

              <span>
                Invite friends
              </span>


            </GemBlock>
            <p1>Earn bonus tokens!</p1>

          </Grid>
          <Grid item md={10} xs={12}>
            <ProfileGrid>
              <InfoBlock>
                <div>
                  {/* <strong>Name</strong> -{" "} */}
                  <strong><span>
                    {userData.member.firstName +
                      " " +
                      userData.member.lastName || "-"}
                  </span>
                  </strong>
                </div>
                <div>
                  {/* <span>Occupation</span> -{" "} */}
                  <span>{userData.member.occupation || ""}</span>
                </div>
                <div>
                  {/* <span>Location</span> -{" "} */}
                  <span1>
                    {userData.member.suburb !== "" && userData.member.suburb +
                      (userData.member.country.countryName !== null ? (
                        ", " + userData.member.country.countryName) : "") || ""}
                  </span1>
                </div>
                <BlockWrapper>
                  <Block>
                    <icon><img src={Icon} style={{ marginTop: "5px" }} /></icon>
                    <div><strong>{(totalHours * 60) || 0}</strong></div>
                    <span>{""}Tokens Collected</span>
                  </Block>
                  <Block style={{ background: "#ffe655", border: "1px solid #edb348", cursor: "pointer" }} onClick={() => setGemOpen(true)}>
                    <icon><img src={Icon} style={{ marginTop: "5px" }} /></icon>
                    <span>View Token Ranking</span>
                  </Block>
                </BlockWrapper>


              </InfoBlock>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextComponent>
                  {/* Token Collected: {(totalHours * 60) || 0} */}
                  <span>Total Given Hours</span>
                </TextComponent>

                <RedBlock>
                  <div>{totalHours || 0}</div>
                  <span>hrs</span>
                </RedBlock>

              </div>


            </ProfileGrid>
            <ProfileGridGrey>
              <img src={Assets1} style={{ marginTop: "10px" }} />
              <MemberFlex>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {/* <h5>Member Status</h5> */}
                  {/* <h5>{memberStatus.currentLevel?.levelInternalName}</h5> */}
                  {/* <h5 style={{ fontWeight: 400 }}>Earned Medallion</h5> */}
                  <h5>Current Level</h5>
                  <h2>{memberStatus.currentLevel?.levelStatusName || "-"}</h2>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {/* <h5></h5> */}
                  {/* <h5>{memberStatus.nextLevel?.levelInternalName}</h5> */}
                  <h5>Next Level</h5>
                  <h2>{memberStatus.nextLevel?.levelStatusName || "-"}</h2>
                </div>
              </MemberFlex>
              <HoursDiv>
                <h5>Hours Until Next Level</h5>
                <div><span>{memberStatus.hoursForNextLevel || "-"} </span><span1>hrs</span1></div>
              </HoursDiv>
            </ProfileGridGrey>



            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <div>
                {/* <span style={{ marginRight: "10px" }}>Invite Friends : </span><IconButton onClick={() => handleInviteDialog()}><ContactMail /></IconButton>
                {loadingInvite && (
                  <CircularProgress
                    size={25}
                  />
                )} */}
              </div>
              <div>
                <span style={{ marginRight: "10px" }}>Share : </span>
                {/* <FacebookShareButton
                url={"http://finalhour.retailcare.com.au/dashboard"}
                size={30}
                style={{ marginRight: "5px" }}
              >
                <FacebookIcon size={30} round />
              </FacebookShareButton>
              <LinkedinShareButton
                url={"http://finalhour.retailcare.com.au/dashboard"}
              >
                <LinkedinIcon size={30} round />
              </LinkedinShareButton> */}

                <FacebookShareButton
                  url={`${window.location.origin}/infographic/${userData.member.memberID}`}
                  size={30}
                  style={{ marginRight: "5px" }}
                >
                  <img src={MetaIcon} alt="meta_icon" style={{ height: "30px", width: "30px" }} />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${window.location.origin}/infographic/${userData.member.memberID}`}
                  style={{ marginRight: "5px" }}
                >
                  <img src={LinkedinLogo} alt="linkedin_icon" style={{ height: "30px", width: "30px" }} />
                </LinkedinShareButton>
                <TwitterShareButton url={`${window.location.origin}/infographic/${userData.member.memberID}`}>
                  <img src={Xicon} alt="X_icon" style={{ height: "30px", width: "30px" }} />
                </TwitterShareButton>
              </div>
            </Box>


          </Grid>
        </Grid>
      </Container>

      {openInvite &&
        <InviteDialog callBack={callBack} />
      }
      <Dialog open={gemOpen} onClose={() => setGemOpen(false)} style={{ minWidth: '800px !important' }} maxWidth="xl">
        <DialogTitle>
          <Box mb={3} textAlign="center">
            <h3>Gamification Points</h3>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!isMobile &&
            <>
              <div>
                <ul>
                  <ItemsBox1>
                    <Name><b>Tokens As Time In Minutes</b></Name>
                    <Hours><b>Hours</b></Hours>
                    <Token><b>Tokens</b></Token>
                  </ItemsBox1>
                </ul>
              </div>

              <div>
                <ul>
                  {gemList.map((gem, index) => (
                    <ItemsBox1 key={gem.id}>
                      <Name>{gem.tokensAsTime}</Name>
                      <Hours>{gem.hours}</Hours>
                      <Token>{gem.token}</Token>
                    </ItemsBox1>
                  ))}
                </ul>
              </div>
            </>
          }
          {isMobile &&
            <div>
              <ul>
                {gemList.map((gem, index) => (
                  <ItemsBox key={gem.id}>
                    {/* <Name>ABC{gem.tokensAsTime}</Name>
                  <Hours>QQQ{gem.hours}</Hours>
                  <Token>WWW{gem.token}</Token> */}

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FlexContent1>
                          <Values>
                            Token as times in minutes
                          </Values>
                          <FlexInnerTitle>
                            <span>{gem.tokensAsTime}</span>
                          </FlexInnerTitle>
                        </FlexContent1>

                        <FlexContent>
                          <Values>
                            Hours
                          </Values>
                          <FlexInnerTitle>
                            <span>{gem.hours}</span>
                          </FlexInnerTitle>
                        </FlexContent>
                        <FlexContent>
                          <Values>
                            Token
                          </Values>
                          <FlexInnerTitle>
                            <span>{gem.token}</span>
                          </FlexInnerTitle>
                        </FlexContent>
                      </Grid>
                    </Grid>
                  </ItemsBox>
                ))}
              </ul>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGemOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ProfileSection>

  );
};

export default MainProfile;
